import React from 'react'
import ReactDOM from 'react-dom/client'

import { init as Worker } from '@vezham/ui/dist/contracts/serviceworker'

import App from './App'

const mountApp = () => {
  const el = document.getElementById('root')
  if (el && el.getAttribute('vapp-mounted')) {
    const root = ReactDOM.createRoot(el)
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
    el.setAttribute('vapp-mounted', 'cloud-cdn')
  }
}

mountApp()
Worker({})
